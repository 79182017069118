import type { TranslationKeys } from '@src/utils/useTypedTranslation'
import type { FC } from 'react'
import Link from 'next/link'
import { Logo } from '@components/ui/Logo'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

interface Props {
  className?: string
  data: {
    widgetTitle?: TranslationKeys['footer']
    lists: {
      id: string
      path?: string
      title: TranslationKeys['common']
      icon?: any
    }[]
    logo?: any
    description?: string
    isCompanyIntroduction?: boolean
  }
  variant?: 'contemporary'
}

const WidgetLink: FC<Props> = ({ className, data }) => {
  const { widgetTitle, lists } = data
  const { description } = data
  const { t } = useTypedTranslation('footer')

  return (
    <div
      className={`${className} ${data?.isCompanyIntroduction && 'col-span-2'}`}
    >
      {data?.isCompanyIntroduction ? (
        <div className="lg:space-y-7.5 me-4 flex flex-col space-y-7">
          <Logo keepText={true} />
          <p className="max-w-[334px] text-sm font-normal leading-6 text-[#1D1E1F]">
            {description}
          </p>
          <ul className="flex items-center space-x-3 text-xs text-body lg:space-x-3.5 lg:text-sm">
            {lists.map((list) => (
              <li
                key={`widget-list--key${list.id}`}
                className="flex items-baseline"
              >
                {list.icon && (
                  <Link href={list.path ?? '#!'}>
                    <span className="relative top-0.5 me-3 text-sm lg:top-1 lg:text-base">
                      {list.icon}
                    </span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <h4 className="mb-5 text-sm font-semibold text-heading md:text-base xl:text-lg 2xl:mb-6 3xl:mb-7">
            {widgetTitle && t(`${widgetTitle}`)}
          </h4>
          <ul className="flex flex-col space-y-3 text-xs text-body lg:space-y-3.5 lg:text-sm">
            {lists.map((list) => (
              <li
                key={`widget-list--key${list.id}`}
                className="flex items-baseline"
              >
                <Link href={list.path ?? '#!'}>
                  {list.icon && (
                    <span className="relative top-0.5 me-3 text-sm lg:top-1 lg:text-base">
                      {list.icon}
                    </span>
                  )}

                  <span className="transition-colors duration-200 hover:text-black">
                    {t(`${list.title}`)}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default WidgetLink
