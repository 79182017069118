import type { ImageUploadProps } from '@api/uploads'
import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { MarketplaceUploadHookResponse } from '@src/types/assets'
import { useCallback, useEffect, useRef } from 'react'
import { backendHook } from '@api/trpc'
import { toastSonner } from '@goatlab/react-ui'
import { gcpService } from '@src/services/gcp/gcp.service'
import {
  defaultMarketplaceAssetUploadState,
  useUploadState,
} from '@src/types/assets'
import { imageService } from '@src/utils/image/image.service'

export const useImageUpload = (): MarketplaceUploadHookResponse => {
  const [assetUploadState, setAssetUploadState] = useUploadState()
  const imageBlobRef = useRef<Blob | null>(null)
  const thumbnailBlobRef = useRef<Blob | null>(null)

  const getUploadLinkHook =
    backendHook.backend.posts.getUploadSession.useMutation()

  const reset = useCallback(() => {
    setAssetUploadState(defaultMarketplaceAssetUploadState)
  }, [setAssetUploadState])

  const uploadProcessedAsset = useCallback(
    async (imageUploadBlob: ImageUploadProps) => {
      reset()
      setAssetUploadState((state) => ({
        ...state,
        assetSelectionTriggered: true,
      }))
      try {
        imageBlobRef.current = imageUploadBlob.imageBlob
        const assetWithFileImage =
          await assetMetadataFromBlobImage(imageUploadBlob)

        if (!assetWithFileImage) {
          return reset()
        }

        const { asset: assetMetadata, imageFile } = assetWithFileImage

        setAssetUploadState((state) => ({
          ...state,
          localAssetUrl: assetMetadata.url,
          assetPreviewImage: assetMetadata.url,
          isProcessingAsset: true,
          assetProcessingProgress: 0,
          assetMetadata,
        }))

        const { resultImage, compressedThumbnail } =
          await processImages(imageFile)

        thumbnailBlobRef.current = compressedThumbnail.compressedBlob

        const [links, linksThumbnail] = await Promise.all([
          getUploadLinkHook.mutateAsync({
            fileName: assetMetadata.originalName,
            // TODO: Fix this for Posts, it needs to be private
            visibility: 'public',
          }),
          getUploadLinkHook.mutateAsync({
            fileName: assetMetadata.originalName,
            visibility: 'public',
          }),
        ])

        setAssetUploadState((state) => ({
          ...state,
          assetProcessingProgress: 1,
          isProcessingAsset: false,
          processedAssetUri: resultImage.compressedUrl,
          finalAssetUrl: links.url,
          signedUploadUrl: links.signedUploadURL,
          processedThumbnailAssetUri: compressedThumbnail.compressedUrl,
          signedUploadThumbnailUrl: linksThumbnail.signedUploadURL,
          assetMetadata: {
            ...assetMetadata,
            thumbnailUrl: linksThumbnail.url,
          },
        }))
      } catch {
        toastSonner.error('Error uploading image')
        reset()
      }
    },
    [getUploadLinkHook, reset],
  )

  useEffect(() => {
    if (
      assetUploadState.isProcessingAsset ||
      assetUploadState.isUploadingAsset ||
      assetUploadState.success ||
      assetUploadState.assetProcessingProgress !== 1
    ) {
      return
    }

    const uploadAsset = async () => {
      setAssetUploadState((state) => ({ ...state, isUploadingAsset: true }))
      try {
        if (!imageBlobRef.current || !thumbnailBlobRef.current) {
          throw new Error('No image blob')
        }
        await Promise.all([
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadUrl || '',
            blob: imageBlobRef.current,
            mime: assetUploadState.assetMetadata?.mimeType || '',
          }),
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadThumbnailUrl || '',
            blob: thumbnailBlobRef.current,
            mime: assetUploadState.assetMetadata?.mimeType || '',
          }),
        ])

        setAssetUploadState((state) => ({
          ...state,
          isUploadingAsset: false,
          success: true,
          done: true,
        }))
      } catch {
        toastSonner.error('Could not upload image')
        setAssetUploadState((state) => ({
          ...state,
          isUploadingAsset: false,
          success: false,
          done: true,
        }))
      }
    }

    if (
      assetUploadState.done === false &&
      assetUploadState.isUploadingAsset === false
    ) {
      void uploadAsset()
    }
  }, [assetUploadState])

  const shouldDisplayPreview =
    (assetUploadState.isProcessingAsset || assetUploadState.isUploadingAsset) &&
    !!assetUploadState.assetPreviewImage

  return {
    uploadProcessedAsset,
    reset,
    shouldDisplayPreview,
    ...assetUploadState,
  }
}

export const extractAssetMetadata = (
  asset: Partial<MarketplaceAsset>,
): MarketplaceAsset => {
  const url = asset.url || ''
  const originalName = asset.originalName || ''
  const mimeType = asset.mimeType || ''
  const isFile = asset.mimeType?.includes('pdf') || false // Validar
  const isVideo = asset.mimeType?.includes('video') || false
  const isImage = asset.mimeType?.includes('image') || false
  const sizeBytes = asset.sizeBytes || 0
  const height = asset.height || 0
  const width = asset.width || 0
  const duration = asset.duration || 0
  const isVertical = height > width

  return {
    url,
    originalName,
    mimeType,
    isFile,
    isImage,
    isVideo,
    sizeBytes,
    width,
    height,
    duration,
    isVertical,
  }
}

export const assetMetadataFromBlobImage = async ({
  imageBlob,
  fileName,
  mime,
}: ImageUploadProps): Promise<{
  imageFile: File
  asset: MarketplaceAsset
} | null> => {
  const url = URL.createObjectURL(imageBlob)

  try {
    const imageFile = new File([imageBlob], fileName, { type: mime })
    const bitmap = await createImageBitmap(imageBlob)
    const { width, height } = bitmap
    const sizeBytes = imageBlob.size

    const asset = extractAssetMetadata({
      url,
      originalName: fileName,
      mimeType: mime,
      width,
      height,
      sizeBytes,
    })

    return { asset, imageFile }
  } catch (err) {
    console.error('Error processing the image Blob:', err)
    return null
  }
}

export const processImages = async (imageFile: File) => {
  const [resultImage, compressedThumbnail] = await Promise.all([
    imageService.resizeAndCompressImage({ imageFile }),
    imageService.resizeAndCompressImage({ imageFile, type: 'thumbnail' }),
  ])
  return { resultImage, compressedThumbnail }
}
