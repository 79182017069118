import type { CSSProperties, JSXElementConstructor } from 'react'
import React from 'react'
import cn from 'clsx'

interface Props {
  variant?: Variant
  className?: string
  style?: CSSProperties
  children?: React.ReactNode | any
  html?: string
}

type Variant =
  | 'mediumHeading'
  | 'heading'
  | 'body'
  | 'pageHeading'
  | 'subHeading'

const Text: React.FC<Props> = ({
  style,
  className,
  variant = 'body',
  children,
  html,
}) => {
  const componentsMap: Record<Variant, React.ComponentType<any> | string> = {
    body: 'p',
    mediumHeading: 'h3',
    heading: 'h4',
    pageHeading: 'h1',
    subHeading: 'h2',
  }

  const Component:
    | JSXElementConstructor<any>
    | React.ReactElement
    | React.ComponentType<any>
    | string = componentsMap[variant]

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {}

  return (
    <Component
      className={cn(
        {
          'text-sm leading-7 text-body sm:leading-6': variant === 'body',
          'text-lg font-bold text-heading md:text-xl lg:text-2xl xl:leading-10 2xl:text-3xl':
            variant === 'mediumHeading',
          'text-sm font-semibold text-heading md:text-base xl:text-lg':
            variant === 'heading',
          'text-2xl font-bold text-heading': variant === 'pageHeading',
          'text-lg font-bold text-heading md:text-2xl xl:text-3xl 2xl:text-4xl':
            variant === 'subHeading',
        },
        className,
      )}
      style={style}
      {...htmlContentProps}
    >
      {children}
    </Component>
  )
}

export default Text
