import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { FC } from 'react'
import { memo } from 'react'
import Image from 'next/image'
import { adjustDimensions } from '@src/utils/adjustDimensions'
import { useWindowSize } from 'react-use'
import type { VideoPlayer } from './video/VideoPlayer'
import { PdfPreview } from './pdf/PdfPreview'

interface AssetRendererProps {
  asset: MarketplaceAsset
  index: number
  currentAssetIndex: number
  isAssetViewable: boolean
  isPreview?: boolean
  isStory?: boolean
}

export const AssetRenderer: FC<AssetRendererProps> = memo(
  ({
    asset,
    index,
    currentAssetIndex,
    isAssetViewable,
    isPreview,
    isStory,
  }) => {
    const height = asset.height || 0
    const width = asset.width || 0

    const dimensions = adjustDimensions({
      width,
      height,
      deviceWidth: Math.min(600, useWindowSize().width),
      isStory,
    })

    if (asset.isImage) {
      return (
        <div
          className={`flex h-full items-center justify-center ${isStory ? 'bg-black' : 'bg-slate-100'}`}
          style={{
            width: dimensions.width,
            height: dimensions.height,
          }}
        >
          <Image
            src={asset.url}
            height={dimensions.height}
            width={dimensions.width}
            alt={asset.originalName}
            className="h-full w-full"
          />
        </div>
      )
    }

    if (asset.isVideo) {
      return (
        <VideoPlayer
          asset={asset}
          isStory={isStory}
          currentAssetIndex={currentAssetIndex}
          index={index}
          isAssetViewable={isAssetViewable}
        />
      )
    }

    if (asset.isFile && asset.mimeType === 'application/pdf') {
      return <PdfPreview asset={asset} isPreview={isPreview} />
    }

    return null
  },
)
