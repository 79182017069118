import type { FC, ReactElement } from 'react'
import { useRouter } from 'next/router'
import { Avatar } from '@components/ui/Avatar'
import { defaultAccountImage } from '@src/utils/constants'

interface AccountSearchResultProps {
  account?: {
    id?: string | null
    displayName?: string | null
    profilePicture?: string | null
    title?: string | null
    slug?: string | null
  }
  accessoryRight?: ReactElement
  disabled?: boolean
  onAvatarPressed?: () => void
  onPressedIn?: () => void
  onPress?: () => Promise<void>
  bottomText?: ReactElement
}

export const AccountSearchResult: FC<AccountSearchResultProps> = ({
  account,
  accessoryRight,
  onAvatarPressed,
  onPress,
  onPressedIn,
  bottomText,
}) => {
  const router = useRouter()

  if (!account) {
    return null
  }

  const handlePress = async () => {
    onPressedIn?.()

    if (onPress) {
      await onPress()
      return
    }

    await router.push(`/${account.slug}`)
    onAvatarPressed?.()
  }

  return (
    <div className="flex w-full items-center px-2">
      <div
        className="flex cursor-pointer items-center justify-center"
        onClick={handlePress}
      >
        <div className="flex-shrink-0 pb-2">
          <Avatar
            uri={account?.profilePicture || defaultAccountImage}
            size={50}
          />
        </div>
        <div className="ml-4 flex flex-col justify-center">
          <p className="text-ellipsis text-base font-semibold">
            {account.displayName}
          </p>
          <p
            className="-mt-1 line-clamp-2 overflow-hidden text-ellipsis text-xs leading-tight text-gray-500"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {account.title}
          </p>
          {bottomText && (
            <p className="-mt-0.5 text-xs text-gray-500">{bottomText}</p>
          )}
        </div>
      </div>

      {accessoryRight && <div className="ml-auto">{accessoryRight}</div>}
    </div>
  )
}
