import type { RouterOutputs } from '@api/trpc'
import type { Unpacked } from '@goatlab/js-utils'
import type { FC } from 'react'
import Image from 'next/legacy/image'
import { RichTextDisplay } from '@components/ui/forms/richText/RichTextDisplay'
import { defaultProfileImg } from '@components/ui/images/images'
import Link from '@components/ui/Link'
import { useSearch } from '@contexts/search/search.context'
import { Strings } from '@goatlab/js-utils'
import { useUIStore } from '@sodium/shared-frontend-schemas'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'

interface SearchResultProps {
  item: Unpacked<RouterOutputs['backend']['search']['products']>
}

export const ProductSearchResult: FC<SearchResultProps> = ({ item }) => {
  const stateRouter = useStateAwareRoutes()
  const { closeSearch } = useUIStore()
  const { resetSearch } = useSearch()

  return (
    <Link
      onClick={() => {
        closeSearch()
        resetSearch()
      }}
      href={`${stateRouter.dynamic.SPACE_PRODUCTS_VIEW({
        space: item.accountSlug,
        productId: item?.slug,
      })}`}
      className="group flex h-auto w-full items-center justify-start"
    >
      <div className="relative me-4 flex h-12 w-12 flex-shrink-0 cursor-pointer overflow-hidden rounded-md bg-gray-200">
        <Image
          src={item?.images[0] || defaultProfileImg}
          width={96}
          height={96}
          loading="eager"
          alt={item.name}
          className="bg-gray-200 object-cover"
        />
      </div>
      <div className="flex w-full flex-col overflow-hidden">
        <h3 className="mb-2 truncate text-sm font-semibold text-heading">
          {item.name}
        </h3>
        <div className="text-sm text-heading">
          <RichTextDisplay
            content={Strings.limit(item.description, 40, '...')}
          />
          <del className="ps-2 font-normal text-gray-400">{}</del>
        </div>
      </div>
    </Link>
  )
}
