import type { FC } from 'react'
import Container from '@components/ui/layout/containers/Container'
import WidgetLink from '@components/ui/widgets/widget-link'
import cn from 'clsx'

interface WidgetsProps {
  widgets: {
    id: number
    widgetTitle: string
    lists: any
    isCompanyIntroduction?: boolean
    logo?: any
  }[]

  variant?: 'contemporary'
}

const Widgets: FC<WidgetsProps> = ({ widgets, variant }) => {
  return (
    <Container>
      <div
        className={cn(
          'grid grid-cols-2 gap-5 pb-9 md:grid-cols-3 md:gap-9 md:pb-14 lg:mb-0.5 lg:grid-cols-4 lg:pb-16 xl:gap-5 2xl:mb-0 2xl:pb-20 3xl:-mb-1 3xl:pb-24',
          {
            'xl:grid-cols-6': variant !== 'contemporary',
            'xl:grid-cols-7': variant === 'contemporary',
          },
        )}
      >
        {widgets?.map((widget) => (
          <WidgetLink
            key={`footer-widget--key${widget.id}`}
            data={widget as any}
            className="pb-3 md:pb-0"
            variant="contemporary"
          />
        ))}
      </div>
    </Container>
  )
}

export default Widgets
