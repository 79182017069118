import type { FC, ReactNode } from 'react'
import { CookieBar } from '@components/common/CookieBar'
import MobileNavigation from '@components/layout/drawerLeft/mobileDrawer'
import { Footer } from '@components/layout/footer/footer'
import { MainTopHeader } from '@components/layout/header/MainTopHeader'
import { Search } from '@components/search/globalSearch/Search'
import { Button } from '@components/ui/buttons/Button2'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { useSite } from '@contexts/site/site.context'
import { SidebarProvider } from '@goatlab/react-ui'
import { useIs } from '@src/hooks/useIs'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { useAcceptCookies } from '@utils/use-accept-cookies'
import { NextSeo } from 'next-seo'

interface LayoutProps {
  children: ReactNode
  showFooter?: boolean
}
export const Layout: FC<LayoutProps> = ({ children, showFooter = true }) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { isMobile } = useIs()
  const { isSpace, isExternalDomain, domain, slug } = useSite()
  const { t } = useTypedTranslation('common')

  const shouldDisplaySpaceSEO = !!(isSpace && isExternalDomain)

  return (
    <>
      <NextSeo
        additionalMetaTags={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
        ]}
        title={shouldDisplaySpaceSEO ? slug : t('meta-title')}
        description={domain ?? ''}
        canonical="https://gealium.com/"
        openGraph={{
          url: shouldDisplaySpaceSEO ? domain : 'https://gealium.com/',
          title: t('meta-title') ?? '',
          description: t('meta-description') ?? '',
          images: [
            {
              url: '/assets/images/og-image-01.jpg',
              width: 800,
              height: 600,
              alt: 'Gealium logo',
            },
            {
              url: '/assets/images/og-image-01.jpg',
              width: 900,
              height: 800,
              alt: 'Gealium logo',
            },
          ],
        }}
      />
      <SidebarProvider>
        <main
          className="flex-1 overflow-x-hidden bg-slate-100"
          style={{
            WebkitOverflowScrolling: 'touch',
            minHeight: '100dvh',
          }}
        >
          <NoSSR>
            <MainTopHeader />
          </NoSSR>

          {children}
          {showFooter && <Footer />}

          {isMobile && (
            <NoSSR>
              <MobileNavigation />
            </NoSSR>
          )}

          <Search />

          <CookieBar
            title={t('text-cookies-title')}
            hide={acceptedCookies}
            action={
              <Button
                onClick={() => {
                  onAcceptCookies()
                }}
                variant="slim"
              >
                {t('text-accept-cookies')}
              </Button>
            }
          />
        </main>
      </SidebarProvider>
    </>
  )
}
