import type { LinkProps as NextLinkProps } from 'next/link'
import type { FC, ReactNode } from 'react'
import { forwardRef } from 'react'
import NextLink from 'next/link'

const Link: FC<NextLinkProps & { className?: string; children: ReactNode }> =
  forwardRef(({ href, children, ...props }, ref) => {
    return (
      <NextLink href={href} ref={ref as any} {...props}>
        {children}
      </NextLink>
    )
  })

export default Link
