import type { TranslationKeys } from '@src/utils/useTypedTranslation'
import type { FC, ReactNode } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Scrollbar from '@components/common/scrollbar'
import { defaultProfileImg } from '@components/ui/images/images'
import { SideBarGroupLink } from '@components/ui/sidebar/sidebarLink'
import { useUI } from '@contexts/ui/ui.context'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { typesenseService } from '@src/services/typesense/typesense.service'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import {
  CalendarDays,
  GraduationCap,
  Home,
  Newspaper,
  Store,
  User2,
} from 'lucide-react'

export const ProfileNav: FC = () => {
  const {
    query: { space },
  } = useRouter()
  const { Modal } = useUI()

  const { t } = useTypedTranslation('common')
  const { data, isLoading } = typesenseService.useSearchAccount({
    q: String(space),
    query_by: 'slug',
  })
  const stateRouter = useStateAwareRoutes()

  const profileSpaceMenu: {
    slug: string
    name: TranslationKeys['common']
    icon: ReactNode
  }[] = [
    {
      slug: stateRouter.dynamic.SPACE_HOME(),
      name: 'breadcrumb-home',
      icon: <Home className="h-5 w-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_BIO(),
      name: 'breadcrumb-bio',
      icon: <User2 className="h-5 w-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_POSTS(),
      name: 'breadcrumb-posts',
      icon: <Newspaper className="h-5 w-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_COURSES(),
      name: 'breadcrumb-courses',
      icon: <GraduationCap className="h-5 w-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_STORE(),
      name: 'breadcrumb-store',
      icon: <Store className="h-5 w-5" />,
    },
  ]

  const profile = data?.hits[0]?.document

  if (!data && !isLoading && !profile) {
    return <>404!</>
  }

  return (
    <>
      <Scrollbar className="menu-scrollbar mb-auto flex-grow">
        <div className="overflow-hidden">
          <div className="mt-10 flex justify-center">
            <Image
              alt="profile picture"
              src={profile?.profilePicture || defaultProfileImg}
              className="-mt-3 h-28 w-28 rounded-full border-solid border-white"
              width={196}
              height={196}
            />
          </div>
          <div className="px-3 pb-6 pt-2 text-center">
            <h3 className="bold font-sans text-sm">{profile?.displayName}</h3>
          </div>

          <div className="px-3 pb-6 pt-2 text-center">
            <button
              className="btn-sm bg-primary-500 text-white hover:bg-primary-800"
              onClick={() => {
                Modal.openBookAppointment()
              }}
            >
              <CalendarDays />
              <span className="ml-2">{t('text-book-appointment')}</span>
            </button>
          </div>
        </div>

        <div className="ml-6">
          <span className="py-2 text-sm font-bold">{t('drawer-services')}</span>
        </div>
        <div className="flex flex-col px-8 py-7 text-heading lg:px-2">
          <ul className="mobileMenu">
            {profileSpaceMenu.map((item) => {
              return (
                <SideBarGroupLink
                  href={item.slug}
                  icon={item.icon}
                  name={t(`${item.name}`)}
                  key={item.slug}
                />
              )
            })}
          </ul>
        </div>
      </Scrollbar>
    </>
  )
}
