import type { formatDuration } from '@src/utils/date/formatWithDateFns'
import type { VideoInputSettings } from '@src/utils/video/video.types'
import React, { useEffect, useState } from 'react'
import { Slider } from '@goatlab/react-ui'
import { motion } from 'framer-motion'

interface VideoTrimProps {
  videoSettings: VideoInputSettings
  onVideoSettingsChange: (value: VideoInputSettings) => void
  disable: boolean
  videoRef: React.RefObject<HTMLVideoElement>
}

export const VideoTrim = ({
  videoSettings,
  onVideoSettingsChange,
  disable,
  videoRef,
}: VideoTrimProps) => {
  const [videoEndTime, setVideoEndTime] = useState(0)
  const { customStartTime, customEndTime } = videoSettings
  const startTime = formatDuration(customStartTime)
  const endTime = formatDuration(customEndTime)

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current
      const handleLoadedMetadata = () => {
        const durationInSeconds = video.duration
        onVideoSettingsChange({
          ...videoSettings,
          customEndTime: durationInSeconds,
        })
        setVideoEndTime(durationInSeconds)
      }

      video.addEventListener('loadedmetadata', handleLoadedMetadata)
      return () => {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata)
      }
    }
  }, [videoSettings, onVideoSettingsChange, videoRef])

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0 }}
      key="drag"
      transition={{ type: 'tween' }}
      className="h-fit rounded-2xl border border-gray-200 bg-gray-100 px-4 py-3"
    >
      <div className="text-sm">
        <div className="mb-2 flex items-center justify-between border-b pb-2">
          <p>Trim Video</p>
        </div>
        <div className="mb-2 flex items-center justify-between border-b pb-2">
          <Slider
            disabled={disable}
            value={[customStartTime, customEndTime]}
            max={videoEndTime}
            step={1}
            className="w-full"
            onValueChange={(value: number[]) => {
              const [start, end] = value
              onVideoSettingsChange({
                ...videoSettings,
                customEndTime: end,
                customStartTime: start,
              })
            }}
          />
        </div>
        <div className="flex justify-between">
          <div>
            <p className="text-gray-500">Start Time</p>
            <p className="font-medium">{startTime}</p>
          </div>
          <div>
            <p className="text-gray-500">End Time</p>
            <p className="font-medium">{endTime}</p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
