import type { ImageUploadProps } from '@api/uploads'
import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { gcpService } from '@src/services/gcp/gcp.service'
import type { MarketplaceUploadHookResponse } from '@src/types/assets'
import { useCallback, useEffect, useRef } from 'react'
import { backendHook } from '@api/trpc'
import { Promises } from '@goatlab/js-utils'
import { toastSonner } from '@goatlab/react-ui'
import {
  defaultMarketplaceAssetUploadState,
  useUploadState,
} from '@src/types/assets'
import { documentService } from '@src/utils/document/document.service'
import { imageService } from '@src/utils/image/image.service'
import { extractAssetMetadata } from './useImageUploads'

export const useDocumentUpload = (): MarketplaceUploadHookResponse => {
  const [assetUploadState, setAssetUploadState] = useUploadState()
  const documentBlobRef = useRef<Blob | null>(null)
  const thumbnailBlobRef = useRef<Blob | null>(null)

  const getUploadLinkHook =
    backendHook.backend.posts.getUploadSession.useMutation()

  const reset = useCallback(() => {
    setAssetUploadState(defaultMarketplaceAssetUploadState)
  }, [setAssetUploadState])

  const uploadProcessedAsset = useCallback(
    async (imageUploadBlob: ImageUploadProps) => {
      reset()
      setAssetUploadState((state) => ({
        ...state,
        assetSelectionTriggered: true,
      }))
      try {
        documentBlobRef.current = imageUploadBlob.imageBlob
        const assetFromBlob = await createDocumentAssetFromBlob(imageUploadBlob)

        if (!assetFromBlob) {
          return reset()
        }

        const { asset: assetMetadata, documentFile } = assetFromBlob

        const originalName = assetMetadata.originalName
        const thumbnailName = originalName.replace('.pdf', '.jpg')

        setAssetUploadState((state) => ({
          ...state,
          localAssetUrl: assetFromBlob.asset.url,
          assetPreviewImage: assetFromBlob.asset.url,
          isProcessingAsset: true,
          assetProcessingProgress: 0,
          assetMetadata,
        }))

        const { documentThumbnail, numPages, compressedPdf } =
          await Promises.props({
            documentThumbnail: documentService.getDocumentThumbnail({
              documentFile,
              fileName: assetFromBlob.asset.originalName,
            }),
            numPages: documentService.getTotalPagesFromPdf(documentFile),
            // Removing PDF compression until we get better resolution
            compressedPdf: assetFromBlob.asset.url, // documentService.compressPdf(asset.uri),
          })

        thumbnailBlobRef.current = documentThumbnail.thumbnailBlob
        const width = documentThumbnail.width
        const height = documentThumbnail.height
        const isVertical = height > width

        setAssetUploadState((state) => {
          return {
            ...state,
            assetMetadata: {
              ...assetMetadata,
              width: documentThumbnail.width,
              height: documentThumbnail.height,
              isVertical,
              pages: numPages,
            },
          }
        })

        const { resultDocument, links, resultImageThumbnail, linksThumbnail } =
          await Promises.props({
            resultDocument: { uri: compressedPdf },
            links: getUploadLinkHook.mutateAsync({
              fileName: `${originalName}`,
            }),
            resultImageThumbnail: imageService.resizeAndCompressImage({
              imageFile: documentThumbnail.imageFile,
              type: 'thumbnail',
            }),
            linksThumbnail: getUploadLinkHook.mutateAsync({
              fileName: `${thumbnailName}`,
              visibility: 'public',
            }),
          })

        setAssetUploadState((state) => {
          return {
            ...state,
            assetProcessingProgress: 1,
            isProcessingAsset: false,
            processedAssetUri: resultDocument.uri,
            finalAssetUrl: links.url,
            signedUploadUrl: links.signedUploadURL,
            processedThumbnailAssetUri: resultImageThumbnail.compressedUrl,
            signedUploadThumbnailUrl: linksThumbnail.signedUploadURL,
            assetMetadata: {
              ...assetMetadata,
              width: documentThumbnail?.width,
              height: documentThumbnail?.height,
              isVertical,
              pages: numPages,
              thumbnailUrl: linksThumbnail.url,
            },
          }
        })
      } catch {
        toastSonner.error('Error uploading image')
        reset()
      }
    },
    [getUploadLinkHook, reset],
  )

  useEffect(() => {
    if (
      assetUploadState.isProcessingAsset ||
      assetUploadState.isUploadingAsset ||
      assetUploadState.success ||
      assetUploadState.assetProcessingProgress !== 1
    ) {
      return
    }

    const uploadAsset = async () => {
      setAssetUploadState((state) => ({ ...state, isUploadingAsset: true }))
      try {
        if (!documentBlobRef.current || !thumbnailBlobRef.current) {
          throw new Error('No image blob')
        }
        await Promise.all([
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadUrl || '',
            blob: documentBlobRef.current,
            mime: assetUploadState.assetMetadata?.mimeType || '',
          }),
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadThumbnailUrl || '',
            blob: thumbnailBlobRef.current,
            mime: 'image/jpeg',
          }),
        ])

        setAssetUploadState((state) => ({
          ...state,
          isUploadingAsset: false,
          success: true,
          done: true,
        }))
      } catch {
        toastSonner.error('Could not upload image')
        setAssetUploadState((state) => ({
          ...state,
          isUploadingAsset: false,
          success: false,
          done: true,
        }))
      }
    }

    if (
      !assetUploadState.isProcessingAsset &&
      assetUploadState.assetProcessingProgress === 1 &&
      assetUploadState.processedAssetUri &&
      assetUploadState.signedUploadUrl &&
      assetUploadState.processedThumbnailAssetUri &&
      assetUploadState.signedUploadThumbnailUrl &&
      !assetUploadState.isUploadingAsset &&
      !assetUploadState.success
    ) {
      void uploadAsset()
    }
  }, [assetUploadState])

  const shouldDisplayPreview =
    (assetUploadState.isProcessingAsset || assetUploadState.isUploadingAsset) &&
    !!assetUploadState.assetPreviewImage

  return {
    uploadProcessedAsset,
    reset,
    shouldDisplayPreview,
    ...assetUploadState,
  }
}

const createDocumentAssetFromBlob = async ({
  imageBlob,
  fileName,
  mime,
}: ImageUploadProps): Promise<{
  documentFile: File
  asset: MarketplaceAsset
} | null> => {
  const url = URL.createObjectURL(imageBlob) // TODO: Check if imageBlob is to big
  try {
    const documentFile = new File([imageBlob], fileName, { type: mime })
    const sizeBytes = imageBlob.size

    const asset = extractAssetMetadata({
      url,
      originalName: fileName,
      mimeType: mime,
      sizeBytes,
    })

    return { asset, documentFile }
  } catch (err) {
    console.error('Error processing the image Blob:', err)
    return null
  }
}
