import type { FC } from 'react'
import React from 'react'

interface GealiumLogoProps {
  size?: number
}
export const GealiumLogo: FC<GealiumLogoProps> = ({ size }) => {
  const width = size || 50

  return (
    <>
      <svg
        width={`${width}`}
        height={`${width}`}
        version="1.1"
        viewBox={`0 0 229.3 229.3`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="clipPath363-6">
            <path d="m0 1e3h1e3v-1e3h-1e3z" />
          </clipPath>
        </defs>
        <g
          transform="matrix(.35278 0 0 -.35278 -61.736 291.04)"
          clipPath="url(#clipPath363-6)"
        >
          <g transform="translate(175 475.69)">
            <path
              d="m0 0c12.487-168.12 153.17-300.69 325-300.69 171.83 0 312.51 132.56 325 300.69h-57.724c-12.31-136.4-127.31-243.23-267.27-243.23-140.01 0-254.96 106.83-267.28 243.23z"
              fill="#4bb8a5"
            />
          </g>
          <g transform="translate(500 825)">
            <path
              d="m0 0c-171.84 0-312.52-132.56-325-300.69h57.723c12.315 136.36 127.27 243.23 267.28 243.23 122.8 0 226.39-82.327 258.29-194.6h-51.254c-30.091 85.029-111.44 145.93-207.04 145.93-113.01 0-206.09-85.073-218.22-194.56h36.796l35.357 76.339c5.058 10.922 20.775 10.495 25.229-.685l72.721-182.67 79.755 256.42c3.834 12.332 21.099 12.998 25.877.992l78.066-196.18 21.207 45.784h168.2c-12.487 168.12-153.17 300.69-325 300.69"
              fill="#4bb8a5"
            />
          </g>
          <g transform="translate(500 281.08)">
            <path
              d="m0 0c113 0 206.09 85.121 218.17 194.6h-36.796l-35.317-76.359c-5.054-10.93-20.779-10.504-25.229.681l-72.713 182.65-79.755-256.38c-3.838-12.332-21.099-12.994-25.877-.992l-78.066 196.13-21.207-45.735h-61.422c12.123-109.48 105.21-194.6 218.22-194.6"
              fill="#4bb8a5"
            />
          </g>
        </g>
      </svg>
    </>
  )
}
