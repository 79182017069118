import type { VideoInputSettings } from '@src/utils/video/video.types'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Button } from '@components/ui/buttons/Button2'
import { SpinnerLoader } from '@components/ui/loaders/SpinnerLoader'
import { VideoFormats } from '@src/utils/video/video.types'
import { useDropzone } from 'react-dropzone'
import { VideoTrim } from './VideoTrim'

export interface VideoUploadProps {
  videoBlob: Blob
  fileName: string
  mime: string
  videoSettings: VideoInputSettings
}

interface VideoUploadType {
  onUploadButtonPressed?: ({
    videoBlob,
    fileName,
    mime,
    videoSettings,
  }: VideoUploadProps) => void
  isLoading?: boolean
}

export const VideoUpload: FC<VideoUploadType> = ({
  onUploadButtonPressed,
  isLoading = false,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [videoSettings, setVideoSettings] = useState<VideoInputSettings>({
    videoType: VideoFormats.mp4,
    customEndTime: 0,
    customStartTime: 0,
    removeAudio: false,
  })

  const [videoUrl, setVideoUrl] = useState<string | null>(null)

  const { getRootProps, getInputProps, isFileDialogActive } = useDropzone({
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/x-matroska': ['.mkv'],
      'video/quicktime': ['.mov'],
      'video/x-msvideo': ['.avi'],
      'video/x-flv': ['.flv'],
    },
    maxSize: 50000000,
    multiple: false,
    onDrop: (files) => {
      setAcceptedFiles(files)
    },
  })

  const handleUpload = () => {
    if (!acceptedFiles[0] || !videoRef.current) return

    onUploadButtonPressed?.({
      videoBlob: new Blob([acceptedFiles[0]], {
        type: acceptedFiles[0].type,
      }),
      fileName: acceptedFiles[0].name,
      mime: acceptedFiles[0].type,
      videoSettings,
    })
  }

  useEffect(() => {
    if (acceptedFiles[0]) {
      const newVideoUrl = URL.createObjectURL(acceptedFiles[0])
      setVideoUrl(newVideoUrl)

      return () => {
        URL.revokeObjectURL(newVideoUrl)
      }
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        const duration = videoRef.current?.duration || 0
        setVideoSettings((settings) => ({
          ...settings,
          customEndTime: duration,
        }))
      })
    }
  }, [videoUrl])

  return (
    <div className="relative flex w-full flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white md:min-w-[600px]">
      <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
        <div className="px-4 text-center">
          {acceptedFiles.length ? (
            isLoading ? (
              <>Uploading Video...</>
            ) : (
              <>
                <video
                  ref={videoRef}
                  controls
                  src={videoUrl || ''}
                  className="mb-4 h-64 w-full object-contain"
                />
                <VideoTrim
                  videoSettings={videoSettings}
                  onVideoSettingsChange={setVideoSettings}
                  disable={isLoading || false}
                  videoRef={videoRef}
                />
              </>
            )
          ) : (
            <div
              {...getRootProps({ className: 'dropzone' })}
              className="cursor-pointer rounded-lg bg-slate-100 p-6"
            >
              <div className="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100">
                {isFileDialogActive ? (
                  <SpinnerLoader text="" className="mr-4" />
                ) : (
                  <svg className="h-6 w-5 fill-current" viewBox="0 0 20 24">
                    {/* SVG icon code */}
                  </svg>
                )}
              </div>
              <h2 className="mb-2 text-2xl font-bold text-slate-800">
                Upload your video
              </h2>
              <div className="mb-6">
                Click to select files or drag & drop some files here
              </div>
              <input {...getInputProps()} />
            </div>
          )}
        </div>
        {acceptedFiles.length ? (
          <div className="-mb-6 mt-6 grid grid-flow-col justify-start sm:auto-cols-max sm:justify-end">
            <Button
              variant="slim"
              onClick={handleUpload}
              loading={isLoading}
              color="black"
            >
              {isLoading ? 'Loading...' : 'Upload'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
