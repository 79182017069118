'use client'

import { Label, Switch } from '@goatlab/react-ui'
import { Facebook, Instagram, Linkedin } from 'lucide-react'

export interface QuickShareConfig {
  facebook: boolean
  instagram: boolean
  linkedin: boolean
}
interface QuickShareToggleProps {
  config: QuickShareConfig
  onChange: (config: QuickShareConfig) => void
}

export function QuickShareToggle({ config, onChange }: QuickShareToggleProps) {
  return (
    <div className="flex items-center gap-4 self-start py-2">
      <div className="flex-1 text-sm font-medium">Also share to:</div>
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Switch
            id="facebook"
            checked={config.facebook}
            disabled
            onCheckedChange={(checked) =>
              onChange({ ...config, facebook: checked })
            }
          />
          <Label htmlFor="facebook" className="flex items-center gap-1">
            <Facebook className="h-4 w-4" />
            <span className="sr-only">Facebook</span>
          </Label>
        </div>
        <div className="flex items-center gap-2">
          <Switch
            id="instagram"
            checked={config.instagram}
            disabled
            onCheckedChange={(checked) =>
              onChange({ ...config, instagram: checked })
            }
          />
          <Label htmlFor="instagram" className="flex items-center gap-1">
            <Instagram className="h-4 w-4" />
            <span className="sr-only">Instagram</span>
          </Label>
        </div>
        <div className="flex items-center gap-2">
          <Switch
            id="linkedin"
            checked={config.linkedin}
            onCheckedChange={(checked) =>
              onChange({ ...config, linkedin: checked })
            }
          />
          <Label htmlFor="linkedin" className="flex items-center gap-1">
            <Linkedin className="h-4 w-4" />
            <span className="sr-only">LinkedIn</span>
          </Label>
        </div>
      </div>
    </div>
  )
}
