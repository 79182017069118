import type { HTMLReactParserOptions } from 'html-react-parser'
import type { FC } from 'react'
import { ScrollToTop, ThemeSwitcher } from '@goatlab/react-ui'
import parse from 'html-react-parser'

interface RichTextDisplayProps {
  content?: string | null
}

export const RichTextDisplay: FC<RichTextDisplayProps> = ({ content }) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      // const tagName = (domNode as any).tagName?.toLowerCase()
      const customComponent = (domNode as any)?.attribs?.[
        'data-custom-react-component'
      ]

      switch (customComponent) {
        case 'ThemeSwitcher': {
          return <ThemeSwitcher />
        }
        case 'ScrollToTop': {
          return <ScrollToTop />
        }
        default: {
          break
        }
      }

      return
    },
  }

  return parse(content || '', options)
}
