import type { VideoUploadProps } from '@components/ui/video/VideoUpload'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import { Ids } from '@goatlab/js-utils'
import { removeFileExtension } from '../files/utils'

interface ConverterEngineParams {
  videoUploadBlob: VideoUploadProps
  width?: number | null
  height?: number | null
}

interface ConverterEngine {
  convertInBackground(
    params: ConverterEngineParams,
    progress?: (props: {
      progress: number
      currentFramePreview?: string
    }) => void,
  ): Promise<{ outputVideoUri: string; originalFileName: string }>
}

export class VideoService implements ConverterEngine {
  private ffmpeg = new FFmpeg()

  /**
   * Initialize FFmpeg if not already loaded
   */
  private async loadFFmpeg() {
    if (!this.ffmpeg.loaded) {
      await this.ffmpeg.load({})
    }
  }

  /**
   * Converts a video to a different format using FFmpeg, reporting progress and extracting a frame as a preview.
   * @param videoBlob The Blob of the video to convert.
   * @param progress Optional callback to report conversion progress and preview frame path.
   * @returns Promise resolving to the URI of the converted video.
   */
  async convertInBackground(
    params: ConverterEngineParams,
    progress?: (props: {
      progress: number
      currentFramePreview?: string
    }) => void,
  ): Promise<{
    outputVideoUri: string
    originalFileName: string
    outputVideoBlob: Blob
  }> {
    await this.loadFFmpeg()
    const videoUploadBlob = params.videoUploadBlob

    // Crear un objeto URL para el Blob de video
    const videoUrl = URL.createObjectURL(videoUploadBlob.videoBlob)

    const fileNameWithExtension = params.videoUploadBlob.fileName
    const originalFileName = removeFileExtension(
      params.videoUploadBlob.fileName,
    )

    const outputVideoName = `${originalFileName}_${Ids.uuid()}.mp4`

    // Cargar el Blob del video al sistema de archivos virtual de FFmpeg
    await this.ffmpeg.writeFile(
      fileNameWithExtension,
      await fetchFile(videoUrl),
    )

    const videoWidth = params.width
    const videoHeight = params.height

    const isVertical = (videoHeight || 0) > (videoWidth || 0)
    const scaleFilter = isVertical ? 'scale=-2:720' : 'scale=1280:-2'
    const videoBitrate = isVertical ? '1500k' : '2500k'

    // Comprobar y formatear los tiempos de inicio y fin si están definidos
    const startTime = this.formatTime(
      params.videoUploadBlob.videoSettings.customStartTime,
    )
    const endTime = this.formatTime(
      params.videoUploadBlob.videoSettings.customEndTime,
    )

    const ffmpegCommand = [
      '-i',
      fileNameWithExtension,
      '-ss',
      startTime,
      '-to',
      endTime,
      '-vcodec',
      'h264',
      '-b:v',
      videoBitrate,
      '-vf',
      `${scaleFilter},format=yuv420p`,
      '-acodec',
      'aac',
      '-b:a',
      '128k',
      outputVideoName,
    ]

    await this.ffmpeg.exec(ffmpegCommand)

    const outputData = await this.ffmpeg.readFile(outputVideoName)
    const outputVideoBlob = new Blob([outputData], { type: 'video/mp4' })
    const outputVideoUri = URL.createObjectURL(outputVideoBlob)

    // Limpiar los archivos temporales
    await this.ffmpeg.deleteFile(fileNameWithExtension)
    await this.ffmpeg.deleteFile(outputVideoName)

    progress?.({ progress: 1 })

    return {
      outputVideoUri,
      originalFileName,
      outputVideoBlob,
    }
  }

  /**
   * Converts a time in seconds to a formatted string "HH:mm:ss".
   * @param seconds Time in seconds.
   * @returns Formatted time string.
   */
  private formatTime = (seconds: number): string => {
    if (isNaN(seconds) || seconds < 0) {
      return '00:00:00' // Retorna un tiempo válido por defecto en caso de error.
    }

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = Math.floor(seconds % 60)

    // Formatear en `hh:mm:ss` con dos dígitos para cada componente
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  /**
   * Genera una miniatura desde un video en un tiempo específico.
   * @param inputFileName Nombre del archivo de entrada para FFmpeg.
   * @param videoBlob El Blob del video.
   * @param timeSeconds Tiempo en segundos para extraer la miniatura.
   * @returns Una promesa con el archivo de la miniatura y sus dimensiones.
   */
  async getThumbnailFile({
    inputFileName,
    videoBlob,
    timeSeconds = 0,
  }: {
    inputFileName: string
    videoBlob: Blob
    timeSeconds?: number
  }): Promise<{
    file: File
    dimensions: { width: number; height: number }
    thumbnailBlob: Blob
  } | null> {
    try {
      // Asegúrate de que FFmpeg está completamente cargado
      await this.loadFFmpeg()
      if (!this.ffmpeg.loaded) {
        console.error('FFmpeg no se ha cargado completamente.')
        return null
      }

      const outputFileName = `thumbnail_${Ids.uuid()}.jpg`

      // Crear una URL para el Blob del video y escribirlo en el sistema de archivos virtual
      const videoUrl = URL.createObjectURL(videoBlob)
      await this.ffmpeg.writeFile(inputFileName, await fetchFile(videoUrl))

      // Formatear el tiempo para FFmpeg y ejecutar el comando para obtener la miniatura
      const timestamp = this.formatTime(timeSeconds)

      await this.ffmpeg.exec([
        '-i',
        inputFileName,
        '-ss',
        timestamp,
        '-frames:v',
        '1',
        outputFileName,
      ])

      const data = await this.ffmpeg.readFile(outputFileName)
      const thumbnailBlob = new Blob([data], { type: 'image/jpeg' })
      const thumbnailFile = new File([thumbnailBlob], outputFileName, {
        type: 'image/jpeg',
      })

      // Crear un bitmap para obtener las dimensiones de la imagen
      const bitmap = await createImageBitmap(thumbnailFile)
      const { width, height } = bitmap

      // Limpiar los archivos temporales
      await this.ffmpeg.deleteFile(inputFileName)
      await this.ffmpeg.deleteFile(outputFileName)

      return {
        file: thumbnailFile,
        dimensions: { width, height },
        thumbnailBlob,
      }
    } catch (err) {
      console.error('Error al generar la miniatura:', err)
      return null
    }
  }
}
