import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { MarketplaceUploadHookResponse } from '@src/types/assets'
import React, { useEffect, useState } from 'react'
import { backendHook } from '@api/trpc'
import { useAuth } from '@components/auth/store/auth.store'
import { DocumentUpload } from '@components/ui/documents/DocumentUpload'
import { RichTextArea } from '@components/ui/forms/richText/RichTextArea'
import { ImageUpload } from '@components/ui/images/ImageUpload'
import { VideoUpload } from '@components/ui/video/VideoUpload'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toastSonner,
} from '@goatlab/react-ui'
import { Form, useZodFormHook } from '@goatlab/react-zod-form'
import { useNewPostStore } from '@sodium/shared-frontend-schemas'
import { useDocumentUpload } from '@src/hooks/uploads/useDocumentUpload'
import { useImageUpload } from '@src/hooks/uploads/useImageUploads'
import { useVideoUpload } from '@src/hooks/uploads/useVideoUploads'
import { z } from 'zod'
import { AssetViewer } from '../post/assetViewer/AssetViewer'
import { AccountSearchResult } from './AccountSearchResult'
import { QuickShareToggle } from './DialogQuickShare'

type UploadType = 'image' | 'video' | 'document'

const newPostSchema = z.object({
  content: z.string(),
})

const replaceAssetOnceUploaded = (
  hook: MarketplaceUploadHookResponse,
  assets: MarketplaceAsset[],
  replaceAsset: (
    oldAsset: MarketplaceAsset,
    newAsset: MarketplaceAsset,
  ) => void,
  reset: () => void,
) => {
  const { finalAssetUrl, success, done, assetMetadata, localAssetUrl } = hook

  if (finalAssetUrl && success && done && assetMetadata) {
    const isAssetPresent = assets.find((asset) => asset.url === localAssetUrl)
    if (isAssetPresent) {
      replaceAsset(isAssetPresent, {
        ...assetMetadata,
        url: finalAssetUrl,
      })
    }
    reset()
  }
}

const addLocalAssetIfNotPresent = (
  hook: MarketplaceUploadHookResponse,
  assets: MarketplaceAsset[],
  addAsset: (newAsset: MarketplaceAsset) => void,
) => {
  const { localAssetUrl, assetMetadata } = hook

  const isAssetPresent = assets.find((asset) => asset.url === localAssetUrl)

  if (!isAssetPresent && assetMetadata && localAssetUrl) {
    addAsset({
      ...assetMetadata,
      url: localAssetUrl,
    })
  }
}

const useHandleFileUpload = (
  uploadHook: MarketplaceUploadHookResponse,
  assets: MarketplaceAsset[],
  replaceAsset: (
    oldAsset: MarketplaceAsset,
    newAsset: MarketplaceAsset,
  ) => void,
  addAsset: (newAsset: MarketplaceAsset) => void,
) => {
  useEffect(() => {
    replaceAssetOnceUploaded(uploadHook, assets, replaceAsset, uploadHook.reset)
  }, [uploadHook.done])

  useEffect(() => {
    addLocalAssetIfNotPresent(uploadHook, assets, addAsset)
  }, [uploadHook.localAssetUrl])
}

export const CreatePostDialog = ({
  isDialogOpen,
  setIsDialogOpen,
}: {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
}) => {
  const [uploadType, setUploadType] = useState<UploadType | null>(null)
  const { user } = useAuth()
  const {
    // TODO: Evaluar que se va a ocupar en este nivel del hook y que no
    hasAssets,
    postHasContent,
    isLoadingMedia,
    isPosting,
    setIsPosting,
    assets,
    setContent,
    content,
    reset,
    postId,
    replaceAsset,
    addAsset,
    setIsLoadingMedia,
  } = useNewPostStore()
  const [quickShareConfig, setQuickShareConfig] = useState({
    facebook: false,
    instagram: false,
    linkedin: false,
  })

  const createPost = backendHook.backend.posts.createPost.useMutation()
  const updatePost = backendHook.backend.posts.updatePost.useMutation()
  const crossPost = backendHook.social.account.shareLinkedInPost.useMutation()
  const utils = backendHook.useUtils()

  const isReadyToPost =
    !isPosting && !isLoadingMedia && (hasAssets || postHasContent)

  const { t } = useTypedTranslation(['forms'])
  const newPostFormHook = useZodFormHook({
    schema: newPostSchema,
    defaultValues: {
      content: postId ? content : '',
    },
  })

  const imageUploadHook = useImageUpload()
  const videoUploadHook = useVideoUpload()
  const documentUploadHook = useDocumentUpload()

  useHandleFileUpload(imageUploadHook, assets, replaceAsset, addAsset)
  useHandleFileUpload(videoUploadHook, assets, replaceAsset, addAsset)
  useHandleFileUpload(documentUploadHook, assets, replaceAsset, addAsset)

  const isHookLoadingMedia =
    imageUploadHook.shouldDisplayPreview ||
    videoUploadHook.shouldDisplayPreview ||
    documentUploadHook.shouldDisplayPreview ||
    assets.some(
      (a) => !(a.url.includes('https://') || a.url.includes('http://')),
    )

  useEffect(() => {
    if (isHookLoadingMedia !== isLoadingMedia) {
      // setAppLoading(isHookLoadingMedia)
      setIsLoadingMedia(isHookLoadingMedia)
    }
  }, [isHookLoadingMedia])

  const handlePost = async (content: string) => {
    setIsPosting(true)
    if (!isReadyToPost) {
      return
    }

    toastSonner.promise(
      (async () => {
        if (postId) {
          await updatePost.mutateAsync({
            postId,
            content,
            assets,
          })
        } else {
          await createPost.mutateAsync({
            content,
            assets,
          })
          // hard coding linkedin in the meantime
          if (quickShareConfig.linkedin) {
            await crossPost.mutateAsync({
              content,
            })
          }
        }
        await utils.backend.posts.homeFeed.invalidate()
      })(),
      {
        loading: `${postId ? 'Updating' : 'Creating'} your post...`,
        success: () => {
          // reset()
          return `Your post has been ${postId ? 'updated' : 'created'}`
        },
        error: (error) => {
          console.error('error??', error)
          // logger.error(error)
          return `There was an error ${postId ? 'updating' : 'creating'} your post`
        },
      },
    )
  }

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(opened) => {
        setIsDialogOpen(opened)
        if (!opened) {
          reset()
        }
      }}
    >
      <DialogContent
        className="max-w-3xl px-6"
        aria-describedby={postId ? 'Update Post' : 'New Post'}
      >
        <DialogHeader>
          <DialogTitle>{postId ? 'Update Post ' : 'New Post'}</DialogTitle>

          <AccountSearchResult account={user?.account} />
        </DialogHeader>
        <Form
          formHook={newPostFormHook}
          onSuccess={async (data) => {
            setIsDialogOpen(false)
            await handlePost(data.content)
          }}
        >
          <RichTextArea
            name={newPostFormHook.path.content}
            placeholder={t('placeholder-new-post', { ns: 'forms' })}
            formHook={newPostFormHook}
            onChange={(html) => {
              setContent(html)
            }}
            autoFocus={true}
            defaultValue={content}
            onImagePressed={() => {
              if (isHookLoadingMedia) {
                return
              }
              setUploadType('image')
            }}
            onVideoPressed={() => {
              if (isHookLoadingMedia) {
                return
              }
              setUploadType('video')
            }}
            onDocumentPressed={() => {
              if (isHookLoadingMedia) {
                return
              }
              setUploadType('document')
            }}
          />
          <div className="max-w-2xl">
            {!!assets.length && (
              <AssetViewer
                assets={assets}
                isViewable={true}
                postId={''}
                isPreview={true}
              />
            )}
          </div>

          <Dialog open={!!uploadType} onOpenChange={() => setUploadType(null)}>
            <DialogContent className="md:min-w-[650px]">
              <DialogHeader>
                <DialogTitle>Upload {uploadType}</DialogTitle>
              </DialogHeader>
              <div className="flex-1">
                {uploadType ? (
                  uploadType === 'image' ? (
                    <ImageUpload
                      cropResizable={true}
                      onUploadButtonPressed={async (data) => {
                        await imageUploadHook.uploadProcessedAsset?.(data)
                        setUploadType(null)
                      }}
                      isLoading={isLoadingMedia}
                    />
                  ) : uploadType === 'video' ? (
                    <VideoUpload
                      onUploadButtonPressed={async (data) => {
                        await videoUploadHook.uploadVideoProcessedAsset?.(data)
                        setUploadType(null)
                      }}
                      isLoading={isLoadingMedia}
                    />
                  ) : uploadType === 'document' ? (
                    <DocumentUpload
                      onUploadButtonPressed={async (data) => {
                        try {
                          await documentUploadHook.uploadProcessedAsset?.(data)
                          setUploadType(null)
                        } catch (err) {
                          console.error(err)
                          setUploadType(null)
                        }
                      }}
                      isLoading={isLoadingMedia}
                    />
                  ) : null
                ) : null}
              </div>
            </DialogContent>
          </Dialog>

          <DialogFooter className="gap-2 p-2">
            <QuickShareToggle
              config={quickShareConfig}
              onChange={setQuickShareConfig}
            />
            <DialogClose asChild>
              <Button variant="outline">
                {t('text-cancel', { ns: 'common' })}
              </Button>
            </DialogClose>
            <Button type="submit" disabled={!isReadyToPost || isPosting}>
              {isLoadingMedia ? '...loading' : postId ? 'Update' : 'Post'}
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
