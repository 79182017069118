// Original code: https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/Sortable.tsx

import type {
  Active,
  Announcements,
  CollisionDetection,
  DraggableSyntheticListeners,
  DropAnimation,
  KeyboardCoordinateGetter,
  MeasuringConfiguration,
  Modifiers,
  PointerActivationConstraint,
  ScreenReaderInstructions,
  UniqueIdentifier,
} from '@dnd-kit/core'
import type {
  AnimateLayoutChanges,
  NewIndexGetter,
  SortingStrategy,
} from '@dnd-kit/sortable'
import type { Transform } from '@dnd-kit/utilities'
import type { LegacyRef, ReactElement } from 'react'
import { useEffect, useRef, useState } from 'react'
import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable'
import { debounce } from 'lodash'
import { createPortal } from 'react-dom'
import { Item } from '../components/Item'
import { List } from '../components/List'
import { Wrapper } from '../components/Wrapper'

export interface RenderItemProps<T extends { id?: string | number }> {
  removeItem: () => void
  dragOverlay: boolean
  dragging: boolean
  sorting: boolean
  index: number
  fadeIn: boolean
  listeners: DraggableSyntheticListeners
  ref: LegacyRef<HTMLDivElement>
  style?: React.CSSProperties | undefined
  transform: Transform | null | undefined
  transition: string | null | undefined
  value: T
}

export interface SortableProps<T extends { id?: string | number }> {
  activationConstraint?: PointerActivationConstraint
  animateLayoutChanges?: AnimateLayoutChanges
  adjustScale?: boolean
  collisionDetection?: CollisionDetection
  coordinateGetter?: KeyboardCoordinateGetter
  Container?: any // To-do: Fix me
  dropAnimation?: DropAnimation | null
  getNewIndex?: NewIndexGetter
  handle?: boolean
  items: T[]
  measuring?: MeasuringConfiguration
  modifiers?: Modifiers
  renderItem: ({
    item,
    props,
  }: {
    item: T
    props: RenderItemProps<T>
  }) => ReactElement
  removable?: boolean
  reorderItems?: typeof arrayMove
  strategy?: SortingStrategy
  style?: React.CSSProperties
  useDragOverlay?: boolean
  getItemStyles?(args: {
    id: UniqueIdentifier
    index: number
    isSorting: boolean
    isDragOverlay: boolean
    overIndex: number
    isDragging: boolean
  }): React.CSSProperties
  wrapperStyle?(args: {
    active: Pick<Active, 'id'> | null
    index: number
    isDragging: boolean
    id: UniqueIdentifier
  }): React.CSSProperties
  isDisabled?(id: UniqueIdentifier): boolean
  onReOrderItems?(params: {
    active: T
    activeIndex: number
    over: T
    overIndex: number
    newOrder: T[]
  }): void
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
}

const screenReaderInstructions: ScreenReaderInstructions = {
  draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `,
}

export function Sortable<T extends { id?: string | number }>({
  activationConstraint,
  animateLayoutChanges,
  adjustScale = false,
  Container = List,
  collisionDetection = closestCenter,
  coordinateGetter = sortableKeyboardCoordinates,
  dropAnimation = dropAnimationConfig,
  getItemStyles = () => ({}),
  getNewIndex,
  handle = false,
  items: initialItems,
  isDisabled = () => false,
  measuring,
  modifiers,
  removable = true,
  renderItem,
  reorderItems = arrayMove,
  strategy = rectSortingStrategy,
  style,
  useDragOverlay = true,
  wrapperStyle = () => ({}),
  onReOrderItems,
}: SortableProps<T>) {
  const placeHolder = () => {}
  const debouncedOnReOrderItems = debounce(onReOrderItems || placeHolder, 100)

  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const [items, setItems] = useState<T[]>([])

  useEffect(() => {
    if (initialItems?.length) {
      setItems(initialItems)
    }
  }, [initialItems])

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      // Disable smooth scrolling in Cypress automated tests
      scrollBehavior:
        typeof globalThis !== 'undefined' && 'Cypress' in globalThis
          ? 'auto'
          : undefined,
      coordinateGetter,
    }),
  )
  const isFirstAnnouncement = useRef(true)
  const getIndex = (id: UniqueIdentifier) => {
    return items.findIndex(
      (item, idx) => String(item?.id === undefined ? idx : item.id) === id,
    )
  }

  const getPosition = (id: UniqueIdentifier) => getIndex(id) + 1
  const activeIndex = activeId ? getIndex(activeId) : -1

  const handleRemove = removable
    ? (id: UniqueIdentifier) => {
        setItems((items) => items.filter((item) => item?.id !== id))
      }
    : undefined

  const announcements: Announcements = {
    onDragStart({ active: { id } }) {
      return `Picked up sortable item ${String(
        id,
      )}. Sortable item ${id} is in position ${getPosition(id)} of ${
        items.length
      }`
    },
    onDragOver({ active, over }) {
      // In this specific use-case, the picked up item's `id` is always the same as the first `over` id.
      // The first `onDragOver` event therefore doesn't need to be announced, because it is called
      // immediately after the `onDragStart` announcement and is redundant.
      if (isFirstAnnouncement.current === true) {
        isFirstAnnouncement.current = false
        return
      }

      if (over) {
        return `Sortable item ${
          active.id
        } was moved into position ${getPosition(over.id)} of ${items.length}`
      }

      return
    },
    onDragEnd({ active, over }) {
      if (over) {
        return `Sortable item ${
          active.id
        } was dropped at position ${getPosition(over.id)} of ${items.length}`
      }

      return
    },
    onDragCancel({ active: { id } }) {
      return `Sorting was cancelled. Sortable item ${id} was dropped and returned to position ${getPosition(
        id,
      )} of ${items.length}.`
    },
  }

  useEffect(() => {
    if (!activeId) {
      isFirstAnnouncement.current = true
    }
  }, [activeId])

  if (!isClient) {
    return null
  }

  return (
    <DndContext
      accessibility={{
        announcements,
        screenReaderInstructions,
      }}
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }
        setActiveId(active.id)
      }}
      onDragEnd={({ over }) => {
        setActiveId(null)

        if (over) {
          const overIndex = getIndex(over.id)
          if (activeIndex !== overIndex) {
            setItems((items) => {
              const newOrder = reorderItems(items, activeIndex, overIndex)

              debouncedOnReOrderItems?.({
                active: items[activeIndex],
                activeIndex,
                over: items[overIndex],
                overIndex,
                newOrder,
              })

              return newOrder
            })
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
      measuring={measuring}
      modifiers={modifiers}
    >
      <Wrapper style={style} center>
        <SortableContext
          items={items.map((item, index) =>
            String(item?.id === undefined ? index : item.id),
          )}
          strategy={strategy}
        >
          <Container>
            {items.map((value, index) => {
              const uniqueId = String(
                value?.id === undefined ? index : value.id,
              )

              return (
                <SortableItem
                  key={uniqueId}
                  id={uniqueId}
                  handle={handle}
                  index={index}
                  style={getItemStyles}
                  wrapperStyle={wrapperStyle}
                  disabled={isDisabled(uniqueId)}
                  item={value}
                  renderItem={renderItem}
                  removeItem={handleRemove}
                  animateLayoutChanges={animateLayoutChanges}
                  useDragOverlay={useDragOverlay}
                  getNewIndex={getNewIndex}
                />
              )
            })}
          </Container>
        </SortableContext>
      </Wrapper>
      {useDragOverlay
        ? createPortal(
            <DragOverlay
              adjustScale={adjustScale}
              dropAnimation={dropAnimation}
            >
              {activeId ? (
                <Item
                  value={items[activeIndex]}
                  index={activeIndex}
                  handle={handle}
                  renderItem={renderItem}
                  wrapperStyle={wrapperStyle({
                    active: { id: activeId },
                    index: activeIndex,
                    isDragging: true,
                    id: items[activeIndex]?.id || activeIndex,
                  })}
                  style={getItemStyles({
                    id: items[activeIndex]?.id || activeIndex,
                    index: activeIndex,
                    isSorting: activeId !== null,
                    isDragging: true,
                    overIndex: -1,
                    isDragOverlay: true,
                  })}
                  dragOverlay
                />
              ) : null}
            </DragOverlay>,
            document.body,
          )
        : null}
    </DndContext>
  )
}

interface SortableItemProps<T extends { id?: string | number }> {
  animateLayoutChanges?: AnimateLayoutChanges
  disabled?: boolean
  getNewIndex?: NewIndexGetter
  id: UniqueIdentifier
  index: number
  handle: boolean
  useDragOverlay?: boolean
  removeItem?(id: UniqueIdentifier): void
  style(values: any): React.CSSProperties
  item: T
  renderItem: ({ item, props }: { item: T; props: any }) => ReactElement
  wrapperStyle: SortableProps<any>['wrapperStyle']
}

export function SortableItem<T extends { id?: string | number }>({
  disabled,
  animateLayoutChanges,
  getNewIndex,
  handle,
  id,
  index,
  item,
  removeItem,
  style,
  renderItem,
  useDragOverlay,
  wrapperStyle,
}: SortableItemProps<T>) {
  const {
    active,
    attributes,
    isDragging,
    isSorting,
    listeners,
    overIndex,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
    getNewIndex,
  })

  return (
    <Item
      ref={setNodeRef}
      value={item}
      disabled={disabled}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handled={
        handle
          ? {
              ref: setActivatorNodeRef,
            }
          : undefined
      }
      renderItem={renderItem}
      index={index}
      style={style({
        index,
        id,
        isDragging,
        isSorting,
        overIndex,
      })}
      removeItem={removeItem ? () => removeItem(id) : undefined}
      transform={transform}
      transition={transition}
      wrapperStyle={wrapperStyle?.({ index, isDragging, active, id })}
      listeners={listeners}
      data-index={index}
      data-id={id}
      dragOverlay={!useDragOverlay && isDragging}
      {...attributes}
    />
  )
}
