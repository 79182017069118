import type { UseZodFormHookReturnType } from '@goatlab/react-zod-form'
import type { FC, ReactNode } from 'react'
import type { components, Options, StylesConfig } from 'react-select'
import { useEffect } from 'react'
import { Strings } from '@goatlab/js-utils'
import { useParseInputProps } from '@goatlab/react-zod-form'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import type { TypedPathWrapper } from '../utils/typedPath'
import { Tooltip } from '../Tooltip'

interface SelectProps {
  label?: string | null
  options: Options<any>
  inModal?: boolean
  defaultValue?: string | null
  ariaLabel?: string
  name: string | boolean | TypedPathWrapper<any, any>
  formHook?: UseZodFormHookReturnType<any>
  onChange?: (value: string) => void
  placeholder?: string
  required?: boolean
  tooltip?: ReactNode
  isClearable?: boolean
  components?: typeof components
  searchable?: boolean
  tabIndex?: number
  className?: string
}

export const Select: FC<SelectProps> = ({
  onChange,
  options,
  defaultValue,
  name: nameProp,
  formHook: hook,
  label,
  placeholder,
  required,
  tooltip,
  isClearable = true,
  searchable = true,
  components,
  tabIndex,
  className,
  inModal,
}) => {
  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })
  const defaultOption = options.find((option) => option?.value === defaultValue)

  useEffect(() => {}, [defaultValue, defaultOption])

  return (
    <div className="flex-1 flex-col pb-6">
      <label
        className="mb-3 block text-sm font-semibold leading-none text-gray-600"
        htmlFor={name}
      >
        <span className="mr-2">{Strings.capitalize(label ?? '')}</span>

        {tooltip && <Tooltip tip={tooltip} className="mr-2" />}
        {required && <span className="text-rose-500"> *</span>}
      </label>

      <Controller
        control={formHook?.control}
        name={name}
        defaultValue={defaultValue}
        render={({
          field: { onChange: hookChange, value: rValue, ...rest },
        }) => {
          const selectedValue = options.find(
            (option) => option?.value === rValue,
          )
          return (
            <ReactSelect
              id={name}
              menuPortalTarget={inModal ? undefined : document.body}
              menuPosition={inModal ? 'absolute' : 'fixed'}
              className={className}
              key={defaultValue}
              placeholder={placeholder}
              value={selectedValue}
              defaultValue={defaultOption}
              onChange={(selected) => {
                const value = selected?.value as string | null
                onChange?.(value ?? '')
                hookChange(value ?? '')
              }}
              options={options}
              components={components ?? {}}
              isClearable={isClearable}
              styles={colorStyles}
              isSearchable={searchable}
              tabIndex={tabIndex}
              {...rest}
            />
          )
        }}
      />

      {errorMessage && (
        <div className="mt-2 pl-1 text-xs text-red-600">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}

const colorStyles: StylesConfig = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option(styles, { isDisabled, isFocused, isSelected }) {
    const selectedColor = '#99d5ca'
    const focusedColor = '#dff2ee'
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? selectedColor
          : isFocused
            ? 'white'
            : undefined,
      color: isSelected ? '#173a33' : undefined,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: focusedColor,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? selectedColor
            : focusedColor,
      },
    }
  },
}
