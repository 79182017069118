export enum VideoFormats {
  mp4 = 'mp4',
  mkv = 'mkv',
  mov = 'mov',
  avi = 'avi',
  flv = 'flv',
  webm = 'webm',
}

export interface VideoInputSettings {
  videoType: VideoFormats
  customEndTime: number
  customStartTime: number
  removeAudio: boolean
}
