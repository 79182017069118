import type { FC, ReactNode } from 'react'
import cn from 'clsx'

interface CookieBarProps {
  className?: string
  title: string
  description?: string
  hide?: boolean
  action?: ReactNode
}

export const CookieBar: FC<CookieBarProps> = ({
  title,
  description,
  className,
  action,
  hide,
}) => {
  return (
    <div
      className={cn(
        'fixed bottom-0 w-full flex-row items-center justify-center bg-white p-5 text-center text-sm font-medium shadow-cookies transition-all duration-300 ease-out sm:z-50',
        {
          transform: true,
          'translate-y-0 opacity-100': !hide,
          'translate-y-full opacity-0': hide,
        },
        className,
      )}
    >
      <span className="mb:block mb-3.5 inline-block leading-6">{title}</span>
      <span className="inline-block md:ms-3">{description}</span>
      {action && action}
    </div>
  )
}
