import React from 'react'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '@goatlab/react-ui'
import { NewsHubList } from './NewsHubList'

interface NewsHubsProps {
  isOpen: boolean
  onClose: () => void
}

export const NewsHubsDialog: React.FC<NewsHubsProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Drawer open={isOpen} onOpenChange={onClose}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>News Hubs</DrawerTitle>
          <DrawerDescription></DrawerDescription>
        </DrawerHeader>
        <div className="flex items-center justify-center space-x-2 pb-8">
          <NewsHubList />
        </div>
      </DrawerContent>
    </Drawer>
  )
}
