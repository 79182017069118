import type { ImageUploadProps } from '@api/uploads'
import type { FC } from 'react'
import { useState } from 'react'
import { Button } from '@components/ui/buttons/Button2'
import { SpinnerLoader } from '@components/ui/loaders/SpinnerLoader'
import { useDropzone } from 'react-dropzone'

export interface DocumentUploadProps {
  documentBlob: Blob
  fileName: string
  mime: string
}

interface DocumentUploadType {
  onUploadButtonPressed?: ({
    imageBlob,
    fileName,
    mime,
  }: ImageUploadProps) => void
  isLoading?: boolean
}

export const DocumentUpload: FC<DocumentUploadType> = ({
  onUploadButtonPressed,
  isLoading,
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const { getRootProps, getInputProps, isFileDialogActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: 30000000,
    multiple: false,
    onDrop: handleFileUpload, // Ejecutar la carga directamente al soltar
  })

  async function handleFileUpload(files: File[]) {
    const file = files[0]
    if (!file) return
    setAcceptedFiles(files)

    const pdfBlob = new Blob([file], { type: file.type })

    // Llamar a la función de carga si está definida
    onUploadButtonPressed?.({
      imageBlob: pdfBlob,
      fileName: file.name,
      mime: file.type,
    })
  }

  return (
    <div className="relative flex w-full flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white md:min-w-[600px]">
      <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
        <div>
          <div className="m-auto mt-16 max-w-2xl">
            <div className="px-4 text-center">
              {acceptedFiles?.length ? (
                isLoading ? (
                  <>Uploading Document...</>
                ) : (
                  <div className="mb-4 text-lg font-bold text-slate-800">
                    Ready to Upload PDF
                  </div>
                )
              ) : (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className="cursor-pointer rounded-lg bg-slate-100 p-6"
                >
                  <div className="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100">
                    {isFileDialogActive ? (
                      <SpinnerLoader text="" className="mr-4" />
                    ) : (
                      <svg className="h-6 w-5 fill-current" viewBox="0 0 20 24">
                        <path
                          className="text-slate-500"
                          d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                        />
                        <path
                          className="text-slate-300"
                          d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                        />
                        <path
                          className="text-slate-400"
                          d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                        />
                      </svg>
                    )}
                  </div>
                  <h2 className="mb-2 text-2xl font-bold text-slate-800">
                    Upload your PDF
                  </h2>
                  <div className="mb-6">
                    Click to select a file or drag a PDF here
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Botón de subida (opcional) */}
        {acceptedFiles?.length ? (
          <div className="-mb-6 mt-6 grid grid-flow-col justify-start sm:auto-cols-max sm:justify-end">
            <Button
              variant="slim"
              onClick={() => handleFileUpload(acceptedFiles)}
              loading={isLoading}
              color="black"
            >
              {isLoading ? 'Loading...' : 'Upload'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
