import type { FC, ReactNode } from 'react'
import { useRouter } from 'next/router'
import { useUIStore } from '@sodium/shared-frontend-schemas'
import Link from '../Link'

interface SideBarGroupLinkProps {
  href: string
  icon: ReactNode
  name: string
}
export const SideBarGroupLink: FC<SideBarGroupLinkProps> = ({
  href,
  icon,
  name,
}) => {
  const { pathname } = useRouter()
  const { closeSideBar } = useUIStore()
  const newPathname = pathname.split('/').slice(2, 3)
  const mainPath = `/${newPathname[0]}`
  const menuPathname = href.split('/').slice(2, 3)
  const menuPath = `/${menuPathname[0]}`

  return (
    <Link key={href} href={href} onClick={closeSideBar}>
      <span
        className={
          mainPath === menuPath
            ? 'menu-link group mb-2 flex cursor-pointer items-center rounded bg-gray-100 px-4 py-2 text-sm font-semibold text-heading lg:px-5 lg:text-base'
            : 'menu-link group mb-2 flex cursor-pointer items-center rounded px-4 py-2 text-sm font-normal text-heading hover:bg-gray-100 lg:px-5 lg:text-base'
        }
      >
        {icon}
        <span className="ml-2 flex-1 whitespace-nowrap text-sm">{name}</span>
      </span>
    </Link>
  )
}
