import type { MutableRefObject } from 'react'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import 'quill/dist/quill.snow.css'
import type QuillClass from 'quill'
import type { QuillOptions } from 'quill'
import type { FC } from 'react'

interface QuillEditorProps {
  quillRef?: MutableRefObject<QuillClass | null>
  readOnly?: boolean
  defaultValue?: string | null
  options?: QuillOptions | null
  onTextChange?: (text: string) => void
  onSelectionChange?: (selection: any) => void
}

export const QuillEditor: FC<QuillEditorProps> = ({
  quillRef,
  readOnly,
  defaultValue,
  onTextChange,
  onSelectionChange,
  options,
}) => {
  const containerRef = useRef<any>(null)
  const defaultValueRef = useRef<any>(defaultValue)
  const onTextChangeRef = useRef<any>(onTextChange)
  const onSelectionChangeRef = useRef<any>(onSelectionChange)

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange
    onSelectionChangeRef.current = onSelectionChange
  })

  useEffect(() => {
    quillRef?.current?.enable(!readOnly)
  }, [quillRef, readOnly])

  useEffect(() => {
    if (defaultValue) {
      defaultValueRef.current = defaultValue
    }
  }, [options, defaultValue])

  useEffect(() => {
    if (quillRef?.current) {
      return
    }
    const container = containerRef.current

    const editorContainer = container.ownerDocument.createElement('div')
    container.append(editorContainer)

    import('quill')
      .then((mod) => {
        const quill = new mod.default(editorContainer, {
          theme: 'snow',
          placeholder: 'Compose an epic...',
          ...options,
        })

        if (quillRef) {
          quillRef.current = quill
        }

        if (defaultValueRef.current) {
          quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current)
        }

        quill.on(mod.default.events.TEXT_CHANGE, (...args) => {
          onTextChangeRef.current?.(...args)
        })

        quill.on(mod.default.events.SELECTION_CHANGE, (...args) => {
          onSelectionChangeRef.current?.(...args)
        })
      })
      .catch((err) => {
        throw err
      })

    return () => {
      if (quillRef) {
        quillRef.current = null
      }

      container.innerHTML = ''
    }
  }, [quillRef])

  return <div ref={containerRef}></div>
}
