import React, { useRef } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useAuth } from '@components/auth/store/auth.store'
import { SearchBox } from '@components/search/globalSearch/SearchBox'
import { LanguageSwitcher } from '@components/ui/LanguageSwitcher'
import { Logo } from '@components/ui/Logo'
import { GealiumCategoryMenu } from '@components/ui/menus/GealiumCategoryMenu'
import { SpaceMenu } from '@components/ui/menus/SpaceMenu'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { useSite } from '@contexts/site/site.context'
import { useLocalization } from '@goatlab/marketplace-i18n'
import { Badge, Button, useScrollHeight } from '@goatlab/react-ui'
import { useUIStore } from '@sodium/shared-frontend-schemas'
import { backendHook } from '@src/api/trpc'
import { useIs } from '@src/hooks/useIs'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import {
  formatMenuFromCategories,
  formatMenuFromWPMenus,
} from '@src/utils/buildHeaders'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { useAddActiveScroll } from '@utils/add-active-scroll'
import clsx from 'clsx'
import { HomeIcon, UsersRoundIcon } from 'lucide-react'
import { AiOutlineMessage } from 'react-icons/ai'
import { sodiumConfig } from '../../../sodiumConfig'

const AuthMenu = dynamic(
  () => import('./authMenu').then((mod) => mod.default),
  { ssr: false },
)
const CartButton = dynamic(
  () => import('@components/cart/cartButton').then((mod) => mod.default),
  {
    ssr: false,
  },
)

type DivElementRef = React.MutableRefObject<HTMLDivElement>

interface MainTopHeaderProps {
  logo?: string
}

export const MainTopHeader: React.FC<MainTopHeaderProps> = () => {
  const { t } = useTypedTranslation('common')
  const currentLanguage = useLocalization().currentLanguageBackendFormat()
  const { navbarOffset } = useScrollHeight()
  const { isSpace, menuItems, logo, isExternalDomain } = useSite()
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { user } = useAuth()

  const { data: gealiumCategories } =
    backendHook.commerce.categories.grouped.useQuery(
      {
        lang: currentLanguage,
      },
      {
        refetchOnWindowFocus: false,
      },
    )

  const { data } =
    backendHook.backend.friendship.getFriendshipNotificationsCount.useQuery(
      undefined,
      {
        enabled: !!user?.account?.id,
      },
    )
  const notificationsCount = data?.friendshipNotificationsCount || 0

  const { data: messageCount } =
    backendHook.backend.chats.getUnreadConversationsCount.useQuery(undefined, {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      enabled: !!user?.account,
    })
  const inboxCount = messageCount || 0

  const shouldDisplaySpaceMenuOnly = !!(isSpace && isExternalDomain)
  const gealiumCategoryMenu = formatMenuFromCategories(gealiumCategories)
  const spaceMenu = formatMenuFromWPMenus(menuItems)

  const { openSearch } = useUIStore()
  const { isMobile } = useIs()
  const { isAuthenticated } = useAuth()
  const isUserAuthenticated = isAuthenticated()

  const siteHeaderRef = useRef() as DivElementRef

  useAddActiveScroll(siteHeaderRef)

  return (
    <header
      id="siteHeader"
      ref={siteHeaderRef}
      className={clsx('active sticky z-50 h-14 w-full')}
    >
      <div
        className={clsx(
          'body-font fixed z-20 h-14 w-full bg-white ps-6 text-gray-700 transition duration-200 ease-in-out',
        )}
        style={{
          top: `${navbarOffset}px`,
        }}
      >
        <div className="flex h-full w-full items-center pr-4 before:absolute before:bottom-0 before:h-px before:w-screen before:bg-[#F1F1F1]">
          <div className="inline-flex w-2/12 items-center justify-start">
            {isMobile && isUserAuthenticated && <AuthMenu />}
            {!isMobile && (
              <Logo
                keepText={!shouldDisplaySpaceMenuOnly}
                logoUrl={(shouldDisplaySpaceMenuOnly && logo) || ''}
              />
            )}
          </div>

          {sodiumConfig.isMarketPlace && (
            <div className="mr-6 flex hidden w-5/12 justify-around sm:block 2xl:me-12 3xl:me-20">
              <div className="headerTopMenu ms-7 pe-2 transition-all duration-100 ease-in-out lg:flex xl:ms-9">
                {shouldDisplaySpaceMenuOnly ? (
                  <SpaceMenu menu={spaceMenu} />
                ) : (
                  <GealiumCategoryMenu categoryMenu={gealiumCategoryMenu} />
                )}
              </div>
            </div>
          )}

          <div className="flex w-10/12 justify-between transition-all">
            {/* Left-aligned SearchBox */}
            <div className="align-center m-2 flex">
              <div className="flex flex-col justify-center">
                <SearchBox onFocus={openSearch} />
              </div>
            </div>

            {/* Right-aligned group of buttons and icons */}
            <div className="flex items-center justify-end">
              {!isMobile && isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_HOME())
                  }}
                  variant="ghost"
                  color="transparent"
                >
                  <div className="align-center flex-col">
                    <div className="flex flex-col items-center">
                      <HomeIcon className="mb-1 h-5 w-5" />
                      <span className="text-xs">Home</span>
                    </div>
                  </div>
                </Button>
              )}

              {!isMobile && isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.static.NETWORK)
                  }}
                  variant="ghost"
                  color="transparent"
                >
                  <div className="align-center relative flex-col">
                    <div className="flex flex-col items-center">
                      <UsersRoundIcon className="mb-1 h-5 w-5" />
                      <span className="text-xs">Network</span>
                    </div>
                    {notificationsCount > 0 && (
                      <Badge
                        variant="destructive"
                        className="absolute right-2 top-0 flex h-4 w-4 items-center justify-center p-0 text-xs"
                      >
                        {notificationsCount}
                      </Badge>
                    )}
                  </div>
                </Button>
              )}

              {isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_INBOX())
                  }}
                  variant="ghost"
                  color="transparent"
                >
                  <div className="align-center relative flex-col">
                    <div className="flex flex-col items-center">
                      <AiOutlineMessage className="mb-1 h-5 w-5" />
                      <span className="text-xs">{t('text-inbox')}</span>
                    </div>
                    {inboxCount > 0 && (
                      <Badge
                        variant="destructive"
                        className="absolute right-0 top-0 flex h-4 w-4 items-center justify-center p-0 text-xs"
                      >
                        {inboxCount}
                      </Badge>
                    )}
                  </div>
                </Button>
              )}

              <NoSSR>
                <div className="hidden md:flex">
                  {!isMobile && !isUserAuthenticated && (
                    <LanguageSwitcher name="language" />
                  )}
                </div>
              </NoSSR>

              {sodiumConfig.isMarketPlace && (
                <div className="flex flex-col items-center justify-center">
                  <CartButton />
                </div>
              )}

              {!isMobile && (
                <div className="ml-4">
                  <AuthMenu />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
