import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import type { FC } from 'react'
import React from 'react'
import Link from 'next/link'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
} from '@goatlab/react-ui'
import { useIs } from '@src/hooks/useIs'
import { adjustDimensions } from '@src/utils/adjustDimensions'
import { DownloadIcon } from 'lucide-react'
import { Document, Page } from 'react-pdf'
import { useWindowSize } from 'react-use'

interface PdfModalProps {
  isShowed: boolean
  onSwipeComplete: React.Dispatch<React.SetStateAction<boolean>>
  handleDocumentLoadSuccess: ({ numPages }: PDFDocumentProxy) => void
  asset: MarketplaceAsset
  numPages: number
}

/**
 * Encodes the filename or path segment of a URL to ensure it is safe for network requests.
 * @param {string} url - The full URL that needs encoding of its parts.
 * @returns {string} - The fully encoded URL.
 */
function encodeURL(url: string) {
  const urlParts = url.split('/')
  const encodedParts = urlParts.map((part, index) => {
    if (index < 3) {
      return part // No encode protocol and domain
    } else {
      return encodeURIComponent(part)
    }
  })
  return encodedParts.join('/')
}

export const PdfModal: FC<PdfModalProps> = ({
  asset,
  isShowed,
  numPages,
  onSwipeComplete,
  handleDocumentLoadSuccess,
}) => {
  const { isMobile } = useIs()
  const dimensions = adjustDimensions({
    width: asset.width || 0,
    height: asset.height || 0,
    deviceWidth: useWindowSize().width,
  })

  const encodedUrl = encodeURL(asset.url)

  return (
    <Dialog open={isShowed} onOpenChange={onSwipeComplete}>
      <DialogContent className="max-w-3xl justify-center rounded-md p-4">
        <DialogHeader>
          <DialogTitle>Visualización de PDF</DialogTitle>
          <DialogDescription>
            Aquí puedes visualizar el documento PDF completo.
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="h-[500px] w-full">
          <Document file={asset.url} onLoadSuccess={handleDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={isMobile ? dimensions.width - 64 : 520}
                height={670}
              >
                <div className="absolute right-2 top-2 text-sm text-neutral-500">
                  <p>
                    Página {index + 1} de {numPages}
                  </p>
                </div>
              </Page>
            ))}
          </Document>
        </ScrollArea>
        <DialogFooter className="pt-2">
          <Link
            href={encodedUrl}
            className="flex items-center justify-center rounded-full bg-slate-500 p-2"
          >
            <DownloadIcon className="h-4 w-4 text-white" />
          </Link>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
