import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import type { MenuItem } from '@src/utils/buildHeaders'
import type { FC } from 'react'
import type { EditText } from 'react-edit-text'
import clsx from 'clsx'
import { Edit, GripVertical, Loader } from 'lucide-react'

interface GealiumMenuSubItemProps {
  menu: MenuItem
  editingItem: number | null
  setEditingItem: (itemId: number | null) => void
  onMenuItemChanged?: (item: MenuItem) => Promise<void>
  onDeleteMenuItem?: (item: MenuItem) => Promise<void>
  onUpdateLinkMenuItemClicked?: (item: MenuItem) => Promise<void>
  dragAndDropListeners?: DraggableSyntheticListeners
}

export const GealiumMenuSubItem: FC<GealiumMenuSubItemProps> = ({
  menu,
  editingItem,
  setEditingItem,
  onMenuItemChanged,
  onUpdateLinkMenuItemClicked,
  dragAndDropListeners,
}) => {
  return (
    <div
      className={clsx(
        'relative block w-[250px] select-none flex-col space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
      )}
    >
      <EditText
        className="text-sm font-medium leading-none"
        defaultValue={menu.label}
        onSave={async (e) => {
          const newMenu = {
            ...menu,
            label: e.value,
          }
          setEditingItem(menu.id)
          await onMenuItemChanged?.(newMenu)
          setEditingItem(null)
        }}
      />

      <EditText
        className="line-clamp-2 text-sm leading-snug text-muted-foreground"
        defaultValue={menu.description}
        onSave={async (e) => {
          const newMenu = {
            ...menu,
            description: e.value,
          }
          setEditingItem(menu.id)
          await onMenuItemChanged?.(newMenu)
          setEditingItem(null)
        }}
      />

      {!editingItem && (
        <div className="absolute right-0 top-0 p-2">
          <div
            onClick={async () => {
              await onUpdateLinkMenuItemClicked?.(menu)
            }}
          >
            <Edit className="mr-1 h-4 w-4 cursor-pointer text-slate-500" />
          </div>
        </div>
      )}

      <div className="absolute bottom-0 right-0 p-2">
        {editingItem === menu.id ? (
          <Loader className="mr-2 h-6 w-6 animate-spin text-slate-500" />
        ) : (
          <GripVertical
            className={clsx('mt-2 h-4 w-4 cursor-grab text-slate-600')}
            {...dragAndDropListeners}
          />
        )}
      </div>
    </div>
  )
}
