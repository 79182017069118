import type { FC } from 'react'
import Image from 'next/image'
import Container from '@components/ui/layout/containers/Container'
import Link from '@components/ui/Link'
import { siteSettings } from '@src/api/settings/site-settings'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import cn from 'clsx'
import { ArrowUp } from 'lucide-react'

interface CopyrightProps {
  payment?: {
    id: string | number
    path?: string
    name: string
    image: string
    width: number
    height: number
  }[]
  variant?: 'contemporary'
}
const year = new Date().getFullYear()
const Copyright: FC<CopyrightProps> = ({ payment, variant }) => {
  const { t } = useTypedTranslation('footer')
  return (
    <div className="mb-2 border-t border-gray-300 pb-16 pt-5 sm:mb-0 sm:pb-20 md:pb-5">
      <Container
        className={cn(
          'flex flex-col-reverse text-center md:flex-row md:justify-between',
          {
            'items-center': variant === 'contemporary',
          },
        )}
      >
        <p
          className={cn('text-xs leading-6 text-body lg:text-sm', {
            'm-0 p-0': variant === 'contemporary',
          })}
        >
          {t('text-copyright')} &copy; {year}&nbsp;
          <a
            className="font-semibold text-gray-700 transition-colors duration-200 ease-in-out hover:text-body"
            href={siteSettings.author.websiteUrl}
          >
            {siteSettings.author.name}
          </a>
          &nbsp; {t('text-all-rights-reserved')}
        </p>

        {payment && (
          <ul className="space-s-4 xs:space-s-5 lg:space-s-7 mx-auto mb-1 hidden flex-wrap items-center justify-center md:mx-0 md:mb-0 md:flex">
            {payment?.map((item) => (
              <li
                className="mb-2 transition hover:opacity-80 md:mb-0"
                key={`payment-list--key${item.id}`}
              >
                <a href={item.path ?? '/#'} target="_blank" rel="noreferrer">
                  <Image
                    src={item.image}
                    alt={item.name ?? ''}
                    height={item.height}
                    width={item.width}
                  />
                </a>
              </li>
            ))}
          </ul>
        )}

        {variant === 'contemporary' && (
          <p className="cursor-pointer text-sm font-semibold leading-[19px] text-[#212121]">
            <Link href="#siteHeader">Scroll to top</Link>

            <ArrowUp className="ms-3 inline" />
          </p>
        )}
      </Container>
    </div>
  )
}

export default Copyright
