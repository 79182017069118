import cn from 'clsx'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'
import type { CSSProperties, FC, ReactNode } from 'react'

interface ScrollbarProps {
  options?: any
  children: ReactNode
  style?: CSSProperties
  className?: string
}

const Scrollbar: FC<ScrollbarProps> = ({
  options,
  children,
  style,
  className,
  ...props
}) => {
  return (
    <OverlayScrollbarsComponent
      options={{
        className: cn('os-theme-thin', className),
        scrollbars: {
          autoHide: 'scroll',
        },
        ...options,
      }}
      style={style}
      {...props}
    >
      {children}
    </OverlayScrollbarsComponent>
  )
}

export default Scrollbar
